<template>
  <div id="content-area" v-if="!message">
    <h1>Er du sikker?</h1>
    <p>Du sletter også alle de undersøgelser, du har sat i gang, og de tilhørende resultater.</p>
    <p><b>Skriv dit password</b> i nedenstående felt og klik derefter på knappen "Slet profil".</p>
    <div class="input-container">
      <input :type="showPassword ? 'text' : 'password'" v-model="password"><button class="password-show-hide" @click="showPassword = !showPassword">{{ showPassword ? 'skjul': 'vis' }}</button>
    </div>
    <p v-if="noPassword" class="error">Du mangler at skrive dit password!</p>
    <nav class="horizontal-flex">
      <base-ui-button @click="$router.push('/')">Annuller</base-ui-button>
      <base-ui-button @click="deleteUser" primary>Slet profil</base-ui-button>
    </nav>
    <p>Har du glemt dit password? Så nulstil det <router-link to="/nulstil-password">her</router-link></p>
  </div>
  <div id="content-area" v-else>
    <h4>{{message}}</h4>
    <nav class="horizontal-flex">
      <base-ui-button @click="$router.push('/')">Tilbage til forsiden</base-ui-button>
    </nav>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { errorMessages } from '../sharedFunctions/errorMessages'

import BaseUiButton from '../components/shared/BaseUiButton.vue'
export default {
  components: { BaseUiButton },
  data () {
    return {
      password: '',
      showPassword: false,
      noPassword: false,
      message: ''
    }
  },
  methods: {
    async deleteUser () {
      try {
        if (this.password !== '') {
          var user = firebase.auth().currentUser
          await firebase.auth().signInWithEmailAndPassword(user.email, this.password)
          const surveys = await firebase.firestore().collection('surveys').where('ownedBy', '==', user.uid).get()
          surveys.docs.forEach(doc => {
            firebase.firestore().collection('surveys').doc(doc.id).delete()
          })
          this.$store.dispatch('survey/clear')
          this.$store.dispatch('response/clear')
          await user.delete()
          this.message = 'Din profil er slettet'
        } else {
          this.noPassword = true
        }
      } catch (error) {
        this.message = errorMessages[error.code] || 'Der skete en fejl, prøv igen senere '
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#content-area
  justify-content center
  max-width 27rem

.error
  color: red;
</style>
