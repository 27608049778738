<template>
  <div>
    <h4>{{message}}</h4>
    <div class="input-container">
      <input :type="showPassword ? 'text' : 'password'" v-model="password">
      <button class="password-show-hide" @click="showPassword = !showPassword">{{ showPassword ? 'skjul': 'vis' }}</button>
    </div>
    <p v-if="noPassword" class="error">Du mangler at skrive dit password!</p>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { errorMessages } from '../sharedFunctions/errorMessages'

export default {
  data () {
    return {
      password: '',
      showPassword: false,
      noPassword: false,
      message: ''
    }
  },
  async mounted () {
    try {
      const code = this.$route.query.oobCode
      await firebase.auth().verifyPasswordResetCode(code)
    } catch (e) {
      this.message = errorMessages[e.code] || 'Der skete en fejl, prøv igen senere '
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
