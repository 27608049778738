<template>
  <div id="content-area">
    <h1>Nulstil password</h1>
    <h4>{{message}}</h4>
    <div class="input-container">
      <input type="email" v-model="email">
    </div>
    <nav class="horizontal-flex">
      <base-ui-button @click="$router.push('/')">Annuller</base-ui-button>
      <base-ui-button @click="sendResetEmail" primary>Send</base-ui-button>
    </nav>
    <p v-if="sent">Email er sendt. Der kan gå nogle minutter før du modtager den.</p>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import BaseUiButton from '../components/shared/BaseUiButton.vue'
import { errorMessages } from '../sharedFunctions/errorMessages'

export default {
  components: { BaseUiButton },
  data () {
    return {
      email: '',
      message: 'Skriv din e-mail for at få tilsendt et link til nulstilling af dit password.',
      sent: false
    }
  },
  methods: {
    async sendResetEmail () {
      try {
        firebase.auth().languageCode = 'dk'
        await firebase.auth().sendPasswordResetEmail(this.email)
        this.sent = true
      } catch (error) {
        this.message = errorMessages[error.code] || 'Der skete en fejl, prøv igen senere '
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#content-area
  justify-content center
  max-width 27rem
</style>
